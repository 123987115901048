import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Button from "../components/button"
import Slider from "react-slick"
import ModalImage from "react-modal-image"
import img1 from "../images/gallery/1.jpg"
import img2 from "../images/gallery/2.jpg"
import img3 from "../images/gallery/3.jpg"
import img4 from "../images/gallery/4.jpg"
import img5 from "../images/gallery/5.jpg"
import img6 from "../images/gallery/6.jpg"
import img7 from "../images/gallery/7.jpg"
import img8 from "../images/gallery/8.jpg"
import img9 from "../images/gallery/9.jpg"
import img10 from "../images/gallery/10.jpg"
import img11 from "../images/gallery/11.jpg"
import img12 from "../images/gallery/12.jpg"
import img13 from "../images/gallery/13.jpg"
import img14 from "../images/gallery/14.jpg"
import img15 from "../images/gallery/15.jpg"
import img16 from "../images/gallery/16.jpg"

const Partners = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "partners-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
        name
      }
      partners: allFile(filter: { relativeDirectory: { eq: "partners" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxHeight: 190, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      gallery: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 900,
      },
      {
        breakpoint: 10000,
        settings: "unslick",
      },
    ],
  }
  const settingsB = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
      },
      {
        breakpoint: 10000,
        settings: "unslick",
      },
    ],
  }

  return (
    <Section>
      <div className="title">
        <h1>УЖЕ ВЫБРАЛИ НАС</h1>
        <div>
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
      </div>

      <div className="partners-slider">
        <Slider {...settings}>
          {data.partners.edges.map(edge => (
            <Img key={edge.node.id} fluid={edge.node.childImageSharp.fluid} />
          ))}
        </Slider>
      </div>
      <ul className="partners">
        {data.partners.edges.map(edge => (
          <li key={edge.node.id}>
            <Img fluid={edge.node.childImageSharp.fluid} />
          </li>
        ))}
      </ul>
      <ul className="gallery-slider">
        <Slider {...settingsB}>
          {data.gallery.edges.map(edge => (
            <li key={edge.node.id}>
              <Img fluid={edge.node.childImageSharp.fluid} />
            </li>
          ))}
        </Slider>
      </ul>

      <ul className="gallery">
        <li>
          <ModalImage hideDownload={true} small={img1} large={img1} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img2} large={img2} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img3} large={img3} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img4} large={img4} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img5} large={img5} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img6} large={img6} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img7} large={img7} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img8} large={img8} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img9} large={img9} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img10} large={img10} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img11} large={img11} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img12} large={img12} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img13} large={img13} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img14} large={img14} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img15} large={img15} />
        </li>
        <li>
          <ModalImage hideDownload={true} small={img16} large={img16} />
        </li>
      </ul>

      <div className="btn-wrap">
        <p className="text">
          КЛИЕНТЫ ДОВЕРЯЮТ НАМ, И НЕ ЗРЯ. У НАС ЕСТЬ ВСЕ НЕОБХОДИМЫЕ СЕРТИФИКАТЫ
          И ЛИЦЕНЗИИ НА ПРАВО ВЫПОЛНЕНИЯ РАБОТ, АТТЕСТАЦИЯ СОТРУДНИКОВ И
          СЕРТИФИКАЦИЯ ISO.
        </p>
        <Button />
      </div>
    </Section>
  )
}
export default Partners

const Section = styled.section`
  .partners {
    display: none;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  padding-top: 0;
  .slick-prev:before {
    content: "‹" !important;
    position: absolute;
    top: -30px;
    left: -6px;
    font-size: 60px !important;
    font-weight: 900;
  }

  .slick-next:before {
    content: "›" !important;
    position: absolute;
    top: -30px;
    right: -6px;
    font-size: 60px;
    font-weight: 900;
  }
  display: grid;
  grid-gap: 2em;
  grid-template-columns: 100%;
  .text {
    padding-bottom: 2em;
  }
  .title h1 {
    font-size: 1.8rem;
    position: relative;
    text-align: center;
    border-bottom: var(--clr-accent) solid 2px;
  }
  .title .gatsby-image-wrapper {
    display: none;
  }
  .slick-next:before,
  .slick-prev:before {
    color: black;
  }

  .partners-slider {
    padding: 1em 1.5em;

    li {
      align-self: center;
    }
  }
  .partners {
    display: none;
  }
  .gallery {
    display: none;
  }
  .gallery,
  .gallery-slider {
    padding: 1em 1.5em;
  }
  @media (min-width: 600px) {
    .title h1 {
      font-size: 3rem;
    }
  }
  @media (min-width: 900px) {
    .text {
      padding-right: 1em;
      padding-bottom: 0;
      color: white;
      padding: 1em;
    }
    grid-gap: 0em;
    max-width: 1400px;
    margin: 0 auto;
    .partners-slider, .gallery-slider {
      display: none;
    }
    .gallery {
      grid-auto-rows: 227px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 0;
      li {
        margin: -0.5px;
      }
      li > div {
        display: flex;
        height: 100%;
      }
      > li > div {
        position: relative;

        :hover::before,
        :hover::after {
          opacity: 1;
        }
        ::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          background: rgba(0, 0, 0, 0.4);
          opacity: 0;
          transition: all 0.3s ease-in-out;
          pointer-events: none;
        }
        ::after {
          content: "+";
          font-size: 5rem;
          color: #fff;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          z-index: 3;
          pointer-events: none;
          transition: 0.3s all ease-in-out;
        }
        img {
          object-fit: cover;
        }
      }
    }
    .title {
      display: grid;
      grid-template-columns: 1fr 1fr 1.55fr;
      grid-template-areas: "h1 h1 img";
      position: relative;
      align-items: center;

      ::before {
        content: "";
        background-color: var(--clr-accent);
        position: absolute;
        top: 0;
        left: -100vw;
        width: calc(100vw + 100%);
        height: 100%;
        z-index: -1;
      }
      h1 {
        border-bottom: none;
        ::before {
          content: none;
        }
        grid-area: h1;
        position: relative;
        white-space: nowrap;
        z-index: 2;
        font-size: 5.0625rem;
        font-weight: 800;
        padding-left: 0.85em;
        @media (max-width: 1430px) {
          font-size: 5.68vw;
        }
        @media (max-width: 1000px) {
          font-size: 5.64vw;
        }
      }
      .gatsby-image-wrapper {
        grid-area: img;
        grid-row: 1;
        display: block;
        filter: grayscale(100%);
        margin-right: -1px;
        transition: all 0.5s ease-in-out;
        :hover {
          filter: grayscale(0);
        }
      }
    }
    .partners {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      padding: 0 4em;
      align-items: center;
    }
    .btn-wrap {
      display: grid;
      grid-template-columns: 1.7fr 48px 1fr;
      position: relative;
      max-width: 1200px;
      margin: 0 auto;
      :before {
        grid-column: 1/2;
        content: "";
        background-color: var(--clr-accent);
        position: absolute;
        top: 0;
        left: -100vw;
        width: calc(100vw + 100%);
        height: 100%;
        z-index: -1;
      }
      button {
        grid-column: 3;
        height: min-content;
        align-self: center;
      }
    }
  }
`
