/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import OptBuilding from "../images/opt-building.jpg"
import OptCheck from "../images/opt-check.png"

//------------------------------------------------------------------------------------------------------------------------------
// OPT - Opportunities - возможности
// 1.25rem  - 20px
// 1.375rem - 22px
// 1.125rem - 18px
// 5.25rem  - 84px
// 2.25rem  - 36px
// 2.5rem   - 40px
// 1.438rem - 23px
// 1.563rem - 25px
// 5.375rem - 85px
//------------------------------------------------------------------------------------------------------------------------------

const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 1200px) 1fr;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(${OptBuilding});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center center;
  @media (min-width: 1130px) {
    background-attachment: fixed;
  }
`

const Wrapper = styled.div`
  grid-column: 2;
  margin: 0;
  padding: 0;
`

const Cards = styled.div`
  display: flex;
  margin: 70px 0 0 0;
  padding: 0;
  justify-content: space-between;
  div:nth-child(2) {
    color: red;
    div {
      div {
        div.opt__name-section__line {
          border-top: red 2px solid;
        }
      }
    }
  }
  @media (max-width: 1366px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
  }
  @media (max-width: 820px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 48px auto;
  margin: 0;
  padding: 0;
  font-family: var(--fw-primary);
  color: white;
  @media (max-width: 1366px) {
    margin: 70px 0 0 0;
    margin: 0.8em;
    margin-top: 3em;
  }
  @media (max-width: 1120px) {
    width: 400px;
  }
  @media (max-width: 851px) {
    grid-template-rows: 100px;
  }
  @media (max-width: 640px) {
    width: 290px;
  }
`

const CardName = styled.div`
  grid-column: 1;
  grid-row: 1 / span 3;
`

const CardName__Rotate = styled.div`
  width: 230px;
  height: 38px;
  display: flex;
  transform: rotate(-90deg);
  position: relative;
  top: 96px;
  left: -102px;
  p {
    font-size: 1.25rem;
    @media (max-width: 640px) {
      font-size: 0.9rem;
    }
  }
  div.opt__name-section__line {
    border-top: white 2px solid;
    top: 46%;
    position: relative;
    width: 100%;
    margin-left: 18px;
    @media (max-width: 640px) {
      top: 12px;
    }
  }
`

const CardTitle = styled.div`
  grid-column: 2;
  grid-row: 2;
  margin: 18px 0 0 0;
  b {
    font-size: 1.375rem;
    white-space: pre-wrap;
    line-height: 1.36;
    @media (max-width: 640px) {
      font-size: 0.9rem;
    }
  }
`

const CardDescription = styled.div`
  grid-column: 2;
  grid-row: 3;
  margin: 18px 0 0 0;
  p {
    font-size: 16px;
    white-space: pre-wrap;
    line-height: 1.36;
    @media (max-width: 640px) {
      font-size: 0.9rem;
    }
  }
`

const CardIcon = styled.div`
  grid-column: 2;
  grid-row: 1;
  height: 158px;
`

const CardIcon__Size = styled.div`
  width: 90px;
  height: 90px;
  margin: 68px 0 0 0;
  @media (max-width: 851px) {
    margin: 0;
  }
`

const Specialists = styled.div`
  margin: 54px 0 0 0;
  padding: 0 24px 0 24px;
  font-family: var(--fw-primary);
`

const Specialists__Title = styled.div`
  margin: 0;
  padding: 0;
  b {
    font-size: 5.25rem;
    white-space: pre-wrap;
    line-height: 1.36;
    color: white;
    display: block;
  }
  @media (max-width: 1120px) {
    b {
      width: 468px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 640px) {
    b {
      font-size: 2rem;
      width: 184px;
    }
  }
`

const Specialists__Flex = styled.div`
  margin: 24px 0 54px 0;
  ul {
    display: flex;
    flex-flow: column wrap;
    list-style-type: none;
    height: 148px;
    width: 1040px;
    @media (max-width: 1120px) {
      width: 100%;
      flex-flow: column nowrap;
      height: auto;
      justify-content: center;
      align-items: center;
    }
    li {
      width: 50%;
      height: 33.3%;
      @media (max-width: 1120px) {
        height: auto;
        width: 520px;
      }
      @media (max-width: 640px) {
        width: 250px;
      }
      i {
        display: inline-block;
        vertical-align: top;
        position: relative;
        background-image: url(${OptCheck});
        background-size: 34px;
        background-repeat: no-repeat;
        height: 34px;
        width: 34px;
        margin: 8px 0 0 0;
        @media (max-width: 640px) {
          height: 24px;
          width: 24px;
          background-size: 24px;
        }
      }
      b {
        font-size: 1.375rem;
        white-space: pre-wrap;
        line-height: 1.36;
        color: white;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 11px 0 0 22px;
        @media (max-width: 640px) {
          margin: 10px 0 0 10px;
          font-size: 0.9rem;
          width: 210px;
        }
      }
    }
  }
`

const Opportunities = () => {
  const data = useStaticQuery(graphql`
    {
      shlapa: allOpportunitiesJson {
        edges {
          node {
            id
            title
            nameSection
            description
            image {
              childImageSharp {
                fluid(maxWidth: 90, maxHeight: 90) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Section>
        <Wrapper>
          <Cards>
            {data.shlapa.edges.map((edge, index) => (
              <CardGrid key={index}>
                <CardName>
                  <CardName__Rotate>
                    <div>
                      <p>{edge.node.nameSection}</p>
                    </div>
                    <div className="opt__name-section__line"></div>
                  </CardName__Rotate>
                </CardName>
                <CardIcon>
                  <CardIcon__Size>
                    <Img
                      fluid={edge.node.image.childImageSharp.fluid}
                      alt={edge.node.title}
                    />
                  </CardIcon__Size>
                </CardIcon>
                <CardTitle>
                  <p>
                    <b>{edge.node.title}</b>
                  </p>
                </CardTitle>
                <CardDescription>
                  <p>{edge.node.description}</p>
                </CardDescription>
              </CardGrid>
            ))}
          </Cards>
          <Specialists>
            <Specialists__Title>
              <b>в штате компании</b>
            </Specialists__Title>
            <Specialists__Flex>
              <ul>
                <li>
                  <i></i>
                  <b>сметчики</b>
                </li>
                <li>
                  <i></i>
                  <b>строительные инженеры</b>
                </li>
                <li>
                  <i></i>
                  <b>проектировщики</b>
                </li>
                <li>
                  <i></i>
                  <b>строительные эксперты</b>
                </li>
                <li>
                  <i></i>
                  <b>архитекторы</b>
                </li>
                <li>
                  <i></i>
                  <b>инженеры приборных обследований</b>
                </li>
              </ul>
            </Specialists__Flex>
          </Specialists>
        </Wrapper>
      </Section>
    </>
  )
}

export default Opportunities
