import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --ff-primary: "Montserrat", sans-serif;
  --ff-secondary: "Source Code Pro", monospace;

  --fw-reg: 400;
  --fw-bold: 800;

  --clr-light: #fff;
  --clr-dark: #121212;
  --clr-accent: #ff0000;

  --fs-h1: 5.375rem;
  --fs-h2: 1.875rem;
  --fs-h3: 1.4375rem;
  --fs-body: 1.125rem;

  --bs: 0.2em 0.3em 0.3em 0em rgba(0,0,0,0.55);
}

@media (max-width: 900px) {
  :root {
    --fs-h1: 3rem;
    --fs-h2: 1.7rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;
  }
}

::selection {
  background: #7b7b7b;
  color: #ffffff;
}

:focus {
  outline: var(--clr-accent);
}

html {
  /* font-size: 62.5%;  */
  /* font-size 1em = 10px on default browser settings */
}

body {
  background: var(--clr-light);
  color: var(--clr-dark);
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  line-height: 1.6;
  font-weight: 500;
}

section {
  padding: 2em 1em;
}
a {
  color: #000;
  text-decoration: none;
  transition: .5s ease-in-out;
  :hover {
    color: var(--clr-accent);
  }
}
b {
  font-weight: var(--fw-bold);
}
li {
  list-style: none;
}

/* Typography */

h1,
h2,
h3 {
  line-height: 1.1;
}

h1 {
  font-size: var(--fs-h1);
}
h2 {
  font-size: var(--fs-h2);
}
h3 {
  font-size: var(--fs-h3);
}

.ReactModal__Overlay, .ReactModal__Overlay--after-open {
  background-color: rgba(0,0,0, 0.75)!important;
}
/* All Styles */
/* main {
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 1200px) 1fr;
  position: relative;
} */
@media (max-width: 1000px) {
  #gatsby-focus-wrapper {
  margin-top: -.9em;
}
}


`
export default GlobalStyle
