import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Button from "./button"
// import decor from "../images/cards/decor.png"

/* GRAPHQL START */
const HeaderCards = () => {
  const data = useStaticQuery(graphql`
    {
      json: allCardsJson {
        edges {
          node {
            title
            description
            id
            image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 170, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section>
      <div className="container">
        <div className="cards-wrap">
          {data.json.edges.map(edge => (
            <figure key={edge.node.id}>
              <Img
                fluid={edge.node.image.childImageSharp.fluid}
                alt={edge.node.title}
              />
              <figcaption>{edge.node.title}</figcaption>
            </figure>
          ))}
        </div>
        <div className="mobile-bottom">
          <p className="text">
            МЫ ОБЛАДАЕМ ЭКСПЕРТНЫМИ КОМПЕТЕНЦИЯМИ В ПРОЕКТНОМ УПРАВЛЕНИИ И
            ОРГАНИЗАЦИИ СТРОИТЕЛЬСТВА «ПОД КЛЮЧ»
          </p>
          <Img fluid={data.json.edges[7].node.image.childImageSharp.fluid} />
        </div>
        <div className="btn-wrap">
          <Button />
          <p className="text">
            МЫ ОБЛАДАЕМ ЭКСПЕРТНЫМИ КОМПЕТЕНЦИЯМИ В ПРОЕКТНОМ УПРАВЛЕНИИ И
            ОРГАНИЗАЦИИ СТРОИТЕЛЬСТВА <br />
            «ПОД КЛЮЧ»
          </p>
        </div>
      </div>
    </Section>
  )
}
export default HeaderCards
/* GRAPHQL END */

/* STYLED-COMPONENTS START */
const Section = styled.section`
  position: relative;
  padding: 2em 0;
  padding-top: 1em;
  .text {
    line-height: 22.5px;
  }
  .container {
    display: grid;
    grid-gap: 2em;
    max-width: 1200px;
    margin: 0 auto;
    .cards-wrap {
      padding: 0 1em;
    }
    .mobile-bottom {
      font-size: 1rem;
      display: grid;
      color: white;
      grid-template-columns: 2.5fr 90px;
      background-color: var(--clr-accent);
      padding: 1em;
      padding-right: 0.3em;
      font-size: 0.9rem;
      .gatsby-image-wrapper {
        height: 75px;
        align-self: center;
      }
    }
    .btn-wrap {
      padding: 0 1em;
    }
  }
  figure {
    /* :last-child figcaption {
      display: none;
    } */
    > div {
      display: none;
    }
    figcaption {
      font-weight: bold;
      padding-left: 0.5em;
      ::before {
        content: "-";
        position: relative;
        left: -5px;
        color: var(--clr-accent);
        height: 5px;
      }
    }
  }
  .btn-wrap {
    > p {
      display: none;
    }
  }
  @media (min-width: 600px) {
    .container {
      .mobile-bottom {
        display: none;
      }
      .btn-wrap {
        > p {
          display: block;
        }
      }
    }
    figure {
      /* :last-child figcaption {
        display: block;
      } */
      > div {
        display: block;
      }
    }

    .cards-wrap {
      display: grid;
      grid-gap: 2em;
      /* > :last-child {
        .gatsby-image-wrapper {
          height: 100%;
        }
        filter: grayscale(0);
        background-color: var(--clr-accent);
        figcaption {
          display: none;
        }
      } */
      figure {
        filter: grayscale(100%);
        transition: filter 0.5s;
        :hover {
          filter: grayscale(0);
        }
      }
      figcaption {
        display: flex;
        white-space: pre;
        text-transform: uppercase;
        position: relative;
        margin-top: -20px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 97%;
        margin-left: auto;
        padding: 0.5em;
        text-align: right;
        box-shadow: var(--bs);
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color: white;
        font-weight: 900;
        font-size: 0.9rem;
        ::before {
          content: "";
          left: -3%;
          position: absolute;
          border-top: 2px solid var(--clr-accent);
          width: 4%;
          top: 50%;
        }
      }
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      grid-gap: 1em;
    }
  }
  .btn-wrap {
    display: grid;
    grid-gap: 2em;
    height: 100%;
    @media (min-width: 900px) {
      display: grid;
      grid-gap: 2em;
      grid-template-columns: 1fr 2fr;
      div {
        align-self: flex-start;
      }
    }
    @media (min-width: 1200px) {
      ::before {
        content: "";
        background: var(--clr-accent);
        position: absolute;
        top: -18px;
        bottom: 0;
        right: 0;
        width: calc(50% + 180px);
        z-index: -1;
      }
      .text {
        color: white;
      }
    }
  }
`
/* STYLED-COMPONENTS END */
