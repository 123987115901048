import React from "react"
import Modal from "react-modal"
import MyForm from "./MyForm"
import styled from "styled-components"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
  },
}

const SmallButton = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <Modal
        backdropColor={"green"}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <MyForm
          style={{
            overflow: "auto",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "-2px",
            right: "3px",
            backgroundColor: "transparent",
            border: "none",
            color: "var(--clr-accent)",
            fontSize: "1.4rem",
            outline: "none",
          }}
          onClick={closeModal}
        >
          &#x2718;
        </button>
      </Modal>
      <Button onClick={openModal}>Заказать услугу</Button>
    </>
  )
}

export default SmallButton

const Button = styled.button`
  text-transform: none;
  /* font-size: 18px;
  font-weight: 400; */
  cursor: pointer;
  padding: 0.2em 0.7em !important;
  font-family: var(--ff-primary);
  border: solid 2px var(--clr-accent) !important;
  transition: all 0.1s ease-in-out;
  :active {
    transform: scale(0.9);
  }
`
