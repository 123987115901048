import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import SmallButton from "../components/smallButton"

/* GRAPHQL START */
const HeaderCards = () => {
  const data = useStaticQuery(graphql`
    {
      json: allCardsJson {
        edges {
          node {
            title
            description
            id
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Section>
      <div className="wrapper">
        <div className="cards cards--top">
          <div>
            <h1 className="decor decor--top">
              <span>УСЛУГИ</span>
              <br /> ЭНЕРГОАЛЬЯНС
            </h1>
          </div>

          <ul className="list list--top">
            {data.json.edges.slice(0, 3).map(edge => (
              <li key={edge.node.id}>
                <figure>
                  <Img
                    fluid={edge.node.image.childImageSharp.fluid}
                    alt={edge.node.title}
                  />
                  <figcaption>{edge.node.title}</figcaption>
                </figure>
                <div className="text">
                  <p>{edge.node.description}</p>
                  <SmallButton />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="cards cards--bottom">
          <div>
            <h1 className="decor decor--bottom">
              <span>УСЛУГИ</span>
              <br /> ЭНЕРГОАЛЬЯНС
            </h1>
          </div>
          <ul className="list list--bottom">
            {data.json.edges.slice(3).map(edge => (
              <li key={edge.node.id}>
                <figure>
                  <Img
                    fluid={edge.node.image.childImageSharp.fluid}
                    alt={edge.node.title}
                  />
                  <figcaption>{edge.node.title}</figcaption>
                </figure>
                <div className="text">
                  <p>{edge.node.description}</p>
                  <SmallButton />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Section>
  )
}
export default HeaderCards
/* GRAPHQL END */

/* STYLED-COMPONENTS START */
const Section = styled.section`
  overflow: hidden;
  padding-bottom: 3em;
  .wrapper .decor {
    /* font-size: 5.375rem; */
    font-weight: 800;
    color: #efefef;
  }

  .decor--top {
    font-size: 32px;
    border-bottom: 4px var(--clr-accent) solid;
    span {
      color: var(--clr-accent);
      font-size: 4.0625rem;
    }
  }
  .decor--bottom {
    span {
      color: black;
    }
    border-left: 4px black solid;
    color: white;
  }
  .wrapper {
    display: grid;
    grid-gap: 2em;
  }
  .cards {
    display: grid;
    grid-gap: 2em;
  }
  .cards--bottom > div {
    display: none;
  }
  .list {
    display: grid;
    grid-gap: 2em;
    .gatsby-image-wrapper {
      height: 135px;
    }
  }
  .list li {
    display: grid;
    grid-gap: 1em;
  }

  /* .list--bottom li:last-child {
    display: none;
  } */

  figcaption {
    white-space: pre;
    text-transform: uppercase;
    position: relative;
    margin-top: -20px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 97%;
    margin-left: auto;
    padding: 0.5em;
    text-align: right;
    box-shadow: var(--bs);
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: white;
    font-weight: 900;
    font-size: 0.9rem;
    ::before {
      content: "";
      left: -3%;
      position: absolute;
      border-bottom: 2px solid var(--clr-accent);
      width: 4%;
      min-width: 15px;
      bottom: 50%;
    }
  }
  button {
    background-color: transparent;
    border: 1.5px solid var(--clr-accent);
    color: var(--clr-accent);
    padding: 0.4em;
    text-transform: uppercase;
    font-weight: 600;
  }

  .text {
    p {
      margin-bottom: 1em;
      font-size: 0.875rem;
      white-space: pre-wrap;
      line-height: 20px;
    }
  }
  @media (min-width: 360px) {
    .wrapper {
      .cards .list .gatsby-image-wrapper {
        height: 200px;
      }
    }
  }
  @media (min-width: 440px) {
    .wrapper {
      .cards .list .gatsby-image-wrapper {
        height: 250px;
      }
    }
  }
  @media (min-width: 600px) {
    .wrapper {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2em;
      position: relative;
      .list {
        .gatsby-image-wrapper {
          height: 200px;
        }
      }
    }
  }
  @media (min-width: 900px) {
    padding-bottom: 0;
    padding-top: 4em;
    .cards--bottom > div {
      display: block;
    }

    .wrapper {
      grid-template-columns: auto;
      max-width: calc(1200px - 2em);
      margin: 0 auto;
      .cards .list .gatsby-image-wrapper {
        height: 250px;
      }
    }
    .cards {
      grid-template-columns: 200px 1fr;
      grid-gap: 3em;
      position: relative;
      .decor--top {
        border-left: none;
      }
    }
    .cards--bottom {
      padding: 4em 0;
      position: relative;
      ::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -100vw;
        height: 100%;
        width: 200vw;
        background-color: #efefef;
        z-index: -1;
      }
    }
    .list {
      grid-gap: 2em;
      grid-column: 2/3;
      border-top: 8px solid var(--clr-accent);
      padding-top: 1em;
    }
    .list--top {
      border-top: 8px solid black;
    }
    .list li {
      grid-template-columns: repeat(autofill, 1fr);
      grid-gap: 2em;
    }

    @media (min-width: 1100px) {
      .wrapper {
        .cards .list .gatsby-image-wrapper {
          height: 150px;
        }
      }
      .list li {
        grid-template-columns: 270px 1fr;
      }

      /* .wrapper .list .gatsby-image-wrapper {
        height: 150px;}
       */
    }

    .text button {
      margin-top: 1em;
    }

    .decor {
      display: inline-block;
      grid-column: 1/2;
      font-size: var(--fs-h1);
      writing-mode: tb-rl;
      transform: rotate(180deg);
      text-align: end;
      border-bottom: solid 8px var(--clr-accent);
      padding-bottom: 0.15em;
    }
  }
  .decor--bottom {
    border-left: none;
    color: white !important;
    border-bottom: solid 0.1em black;
    span {
      font-size: 4.0625rem;
    }
  }
`
/* STYLED-COMPONENTS END */
