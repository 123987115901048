import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalStyle from "../components/globalStyle"
import About from "../components/about"
import Opportunities from "../components/opportunities"
import HeaderCards from "../components/headerCards"
import Services from "../components/services"
import Partners from "../components/partners"
import SliderMob from "../components/sliderMob"
import SliderPad from "../components/sliderPad"
import SliderFHD from "../components/sliderFHD"

const IndexPage = () => (
  <Layout>
    <GlobalStyle />
    <SEO title="Главная" />
    <HeaderCards />
    <About />
    <Opportunities />
    <Services />
    <Partners />
    <SliderMob />
    <SliderPad />
    <SliderFHD />
  </Layout>
)
export default IndexPage
