import Slider from "react-slick"
import Lightbox from "react-image-lightbox"
import React from "react"
import "react-image-lightbox/style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"
import img1 from "../images/certificates/1.jpg"
import img2 from "../images/certificates/2.jpg"
import img3 from "../images/certificates/3.jpg"
import img4 from "../images/certificates/4.jpg"
import img5 from "../images/certificates/5.png"
import img6 from "../images/certificates/6.png"
import img7 from "../images/certificates/7.png"
import img8 from "../images/certificates/8.png"
import img9 from "../images/certificates/9.jpg"
import img10 from "../images/certificates/10.jpg"
import img11 from "../images/certificates/11.jpg"
import img12 from "../images/certificates/12.jpg"
import img13 from "../images/certificates/13.jpg"
import img14 from "../images/certificates/14.jpg"
import img15 from "../images/certificates/15.jpg"

class SliderMob extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        img11,
        img12,
        img13,
        img14,
        img15,
      ],
      current: "",
    }
  }

  getSliderSettings() {
    return {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  }

  handleClickImage = (e, image) => {
    e && e.preventDefault()

    this.setState({
      current: image,
    })
  }

  handleCloseModal = (e) => {
    e && e.preventDefault()

    this.setState({
      current: "",
    })
  }

  render() {
    const settings = this.getSliderSettings()
    const { images, current } = this.state

    return (
      <Section>
        <Slider {...settings}>
          {images.map((image, index) => (
            <img
              src={image}
              key={index}
              onClick={(e) => this.handleClickImage(e, image)}
            />
          ))}
        </Slider>

        {current && (
          <Lightbox mainSrc={current} onCloseRequest={this.handleCloseModal} />
        )}
      </Section>
    )
  }
}

export default SliderMob

const Section = styled.section`
  display: none;
  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
    .slick-dots li {
      width: 15px;
    }
    .slick-prev:before {
      content: "‹" !important;
      position: absolute;
      top: -30px;
      left: -10px;
      font-size: 60px;
      font-weight: 900;
    }

    .slick-next:before {
      content: "›" !important;
      position: absolute;
      top: -30px;
      right: -10px;
      font-size: 60px;
      font-weight: 900;
    }
    .slick-slide {
      height: 100%;
    }
    .slick-slide img {
      transition: all 0.3s ease-in-out;
      height: 100%;
    }
    .slick-slide > div {
      position: relative;
      overflow: hidden;
      display: flex;
    }

    .slick-slide > div:hover ::before {
      transform: scale(1.025);
      content: "";
      color: white;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      pointer-events: none;
    }
    .slick-slide > div ::after {
      content: "+";
      font-size: 5rem;
      color: #fff;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      z-index: 3;
      pointer-events: none;
      transition: 0.3s all ease-in-out;
    }
    .slick-slide > div :hover ::after {
      opacity: 1;
    }
    .slick-slide > div :hover img {
      transform: scale(1.05);
    }

    .slick-dots li {
      margin: 0;
    }
    .slick-slide {
      padding: 0 0.5em;
    }
    .slick-prev:before,
    .slick-next:before {
      color: black;
    }
    padding-left: 3em;
    padding-right: 3em;
    max-width: 1200px;
    margin: 0 auto;
    .slick-slider {
      display: grid !important;
    }
    .wrapper {
      display: none;
    }
  }
`
