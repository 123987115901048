import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const ComponentName = () => {
  const data = useStaticQuery(graphql`
    {
      a: file(
        relativePath: {
          eq: "staff/garnik-arakelyan-uchreditel-ehnergoalyans-alyansstrojproekt-generalnyj.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      b: file(
        relativePath: {
          eq: "staff/ilya-borisov-uchreditel-ehnergoalyans-alyansstrojproekt-generalnyj.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      c: file(
        relativePath: {
          eq: "staff/bataev-aleksej-glavnyj-arhitektor-proekta.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      d: file(
        relativePath: {
          eq: "staff/balandin-viktor-aleksandrovich-rukovoditel-otdela-instrumentalnyh.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      e: file(
        relativePath: {
          eq: "staff/oganesyan-grigorij-sejranovich-rukovoditel-po-razvitiyu-kompanii.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      f: file(
        relativePath: {
          eq: "staff/dolgov-ilya-aleksandrovich-rukovoditel-otdela-izyskanij.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      g: file(
        relativePath: { eq: "staff/skvorcova-yana-mihajlovna-arhitektor.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      h: file(relativePath: { eq: "staff/inzhener-genplana.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      i: file(relativePath: { eq: "staff/arhitektor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section>
      <div className="wrapper">
        <h1>ДЕЛАЕМ ЛУЧШЕ</h1>
        <figure className="owner-card owner-card-a">
          <Img fluid={data.b.childImageSharp.fluid} />
          <figcaption>
            <p>
              <span>Илья Борисов</span> <br />
              Учредитель ЭнергоАльянс, АльянсСтройПроект <br />
              Генеральный директор АльянсСтройПроект
            </p>
          </figcaption>
        </figure>
        <figure className="owner-card owner-card-b">
          <Img fluid={data.a.childImageSharp.fluid} />
          <figcaption>
            <p>
              <span>Гарник Аракелян</span> <br />
              Учредитель ЭнергоАльянс, АльянсСтройПроект
              <br />
              Генеральный директор ЭнергоАльянс
            </p>
          </figcaption>
        </figure>
        <div className="about">
          <h3>О КОМПАНИИ</h3>
          <p>
            Группа компаний АЛЬЯНС создана в 2013 г. с этого времени
            профессионально оказывает свои услуги на рынке строительства,
            комплексного проектирования и инженерных услуг. Благодаря большому
            опыту и применению прогрессивных технологий Группа берется за самые
            сложные объекты и исполняет их в кратчайшие сроки, зарекомендовав
            себя на рынке, как надежный партнер вашего бизнеса.Вне зависимости
            от объемов и сложности поставленной перед нами задачи, мы применяем
            «умный» подход и всегда находим для заказчиков-инвесторов наиболее
            оптимальные пути для успешного завершения объекта. Наш опыт работы в
            Калининградской области позволяет нам работать с высокой
            эффективностью, принимая во внимание особенности и специфику
            региона. Учитывая сложный и комплексный характер вопросов,
            возникающих при строительстве больших значимых объектов, мы уделяем
            особое внимание поиску разумного компромисса для всех
            заинтересованных сторон, включая инвесторов, собственников,
            городские и региональные власти, а также гражданскоеобщество и
            конечных пользователей.
            <br />
            <br />
            <b>
              С уважением, руководители <span>ГК АЛЬЯНС</span>
            </b>
          </p>
        </div>

        <div className="question">
          <div className="email">
            <h2>
              ЗАДАТЬ ВОПРОС <br />
              РУКОВОДИТЕЛЮ
            </h2>
            <div>
              <svg fill="black" id="svg-mail" width="50" height="40">
                <path d="M34 0H3 2l16 17 4-4L35 0h-1zm3 2L24 14l13 12V3 2zM0 2v24l13-12L0 2z" />
                <path d="M23 16l-4 3h-1l-4-3L2 28h33L23 16z" />
              </svg>
              <a href="mailto:sales@energoalyans.com">
                <p>sales@energoalyans.com</p>
              </a>
            </div>
          </div>
        </div>
        <div className="office-contact">
          <h2>СВЯЗАТЬСЯ С ОФИСОМ</h2>
          <div>
            <svg
              id="svg-phone"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M503 129a339 339 0 00-494 0 33 33 0 000 44l49 52c11 12 32 12 44 0 16-17 35-31 55-42 10-5 17-17 17-27l7-56c55-17 96-17 150 0l7 55c0 11 6 22 17 28 20 11 39 25 55 42a30 30 0 0044 0l49-52c12-12 12-32 0-44z" />
              <path d="M395 220c-9-17-28-28-47-28H164c-19 0-38 11-47 28L49 348c-4 8-6 17-6 25v75c0 24 19 43 42 43h342c23 0 42-19 42-43v-75c0-8-2-17-6-25l-68-128zM192 427a21 21 0 110-43 21 21 0 010 43zm0-64a21 21 0 110-43 21 21 0 010 43zm0-64a21 21 0 110-43 21 21 0 010 43zm64 128a21 21 0 110-43 21 21 0 010 43zm0-64a21 21 0 110-43 21 21 0 010 43zm0-64a21 21 0 110-43 21 21 0 010 43zm64 128a21 21 0 110-43 21 21 0 010 43zm0-64a21 21 0 110-43 21 21 0 010 43zm0-64a21 21 0 110-43 21 21 0 010 43z" />
            </svg>
            <a href="tel:+7(4012)697004">
              <h2>т.8(4012) 697-004</h2>
            </a>
          </div>
        </div>
        <div className="staff">
          <figure className="staff-card staff-card--a">
            <Img fluid={data.d.childImageSharp.fluid} />
            <figcaption>
              <p>
                <span>Баландин Виктор</span>
                <br />
                руководитель отдела инструментальных обследований
              </p>
            </figcaption>
          </figure>
          <figure className="staff-card staff-card--b">
            <Img fluid={data.c.childImageSharp.fluid} />
            <figcaption>
              <p>
                <span>Батаев Алексей</span>
                <br />
                главный инженер проекта
              </p>
            </figcaption>
          </figure>
          <figure className="staff-card staff-card--c">
            <Img fluid={data.e.childImageSharp.fluid} />
            <figcaption>
              <p>
                <span>Оганесян Григорий</span>
                <br />
                Руководитель по развитию компании
              </p>
            </figcaption>
          </figure>

          <figure className="staff-card staff-card--c">
            <Img fluid={data.f.childImageSharp.fluid} />
            <figcaption>
              <p>
                <span>Долгов Илья</span>
                <br />
                Руководитель отдела изысканий
              </p>
            </figcaption>
          </figure>

          <figure className="staff-card staff-card--c">
            <Img fluid={data.g.childImageSharp.fluid} />
            <figcaption>
              <p>
                <span>Скворцова Яна</span>
                <br />
                Архитектор
              </p>
            </figcaption>
          </figure>

          <figure className="staff-card staff-card--c">
            <Img fluid={data.h.childImageSharp.fluid} />
            <figcaption>
              <p>
                <span>Кочев Дмитрий</span>
                <br />
                Инженер генплана
              </p>
            </figcaption>
          </figure>

          <figure className="staff-card staff-card--c">
            <Img fluid={data.i.childImageSharp.fluid} />
            <figcaption>
              <p>
                <span>Батаева Анастасия</span>
                <br />
                Архитектор
              </p>
            </figcaption>
          </figure>
        </div>
      </div>
    </Section>
  )
}
export default ComponentName

/*GRID*/
const Section = styled.section`
  z-index: 0;
  background-color: #efefef;
  position: relative;
  .wrapper {
    
    grid-template-columns: 1fr;
    grid-template-areas:
      "h1"
      "o-c-a"
      "o-c-b"
      "about"
      "question"
      "off-c"
      "staff";
    z-index: 1;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 4em;
    > * {
      z-index: 1;
    }
    h1 {
      grid-area: h1;
      font-weight: 800;
    }
    .owner-card-a {
      grid-area: o-c-a;
    }
    .owner-card-b {
      grid-area: o-c-b;
    }
    .question {
      
      grid-area: question;
      border: 4px solid var(--clr-accent);
      display: grid;
      grid-template-columns: repeat(autofit, minmax(100px, 1fr));
      grid-gap: 2em;
      padding: 1em;
      .email {
        display: grid;
        grid-gap: 1em;
        div {
          display: flex;
        }
        p {
          font-size: 16px;
        }
      }
      .name {
        display: grid;
        grid-gap: 1em;
      }
    }
    .owner-card {
      display: grid;
      grid-gap: 1em;
      grid-template-rows: 300px 1fr;
      grid-template-columns: 1fr;
      p {
        line-height: 1.4;
        font-size: 0.8125rem;
        font-weight: 700;
        span {
          font-size: 0.875rem;
          font-weight: 800;
        }
      }
    }
    .about {
      grid-area: about;
      display: grid;
      grid-gap: 1em;
      p {
        font-size: 1rem;
        line-height:22.4px;
        font-weight: 500;
        b {
          font-weight: 800;
          span{
            white-space: nowrap;
          }
          
        }
      }
      h3 {
        font-size: 1.875rem;
      }
      
      
  
    }
    .staff-card {
      display: grid;
      grid-gap: 1em;
      p {
        line-height: 1.4;
        font-size: 0.8125rem;
        font-weight: 700;
        span {
          font-size: 0.875rem;
          font-weight: 800;
        }
      }
    }
    .office-contact {
      grid-area: off-c;
      display: grid;
      grid-gap: 1em;
      > h2 {
        border-bottom: 4px solid var(--clr-accent);
        font-size: 1.625rem;
       
      }
      div {
        display: grid;
        grid-template-columns: 40px 1fr;
        align-items: center;
        grid-gap: 1em;
        svg {
          width: 40px;
        }
      }
    }
    .staff {
      grid-area: staff;
      grid-template-columns: 1fr;
      grid-gap: 2em;
      display: grid;
      .staff-card {
        grid-template-rows: 300px 1fr;
        p {
          font-size: 	0.875rem;
        }
        span {
          line-height: 2em;
          font-size: 1rem;
        }
      }
    }
    
    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "h1 h1"
        "o-c-a o-c-b"
        "about about"
        "question question"
        "off-c off-c"
        "staff staff";
        
      .staff {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2em;
        .staff-card {
          grid-template-rows: 300px 1fr;
        }
      }
      .office-contact {
      > h2 {
        font-size: 1.875rem;
      }
       }
       @media (min-width: 1000px) {
        .staff {
        display: grid;
        grid-template-columns: repeat(3, 26%);
        grid-gap: 56px;
        justify-content: space-between;
        .staff-card {
          grid-template-columns: 1fr;
          grid-template-rows: 250px auto;
          figcaption {
            align-self: end;
          }
        }
      }
       }
    @media (min-width: 1130px) {
      .question .email {
        p {
          font-size: 24px;
          line-height: 28px;
        }
      }

      grid-template-rows: auto 330px 60px;
      grid-template-columns: 450px 1fr 1fr 1fr;
      grid-template-areas:
        "h1 h1 h1 h1"
        "o-c-a about about about"
        "o-c-b about about about"
        "o-c-b question question question"
        "o-c-b . . ."
        "off-c off-c off-c off-c"
        "staff staff staff staff";
      .office-contact {
        grid-gap: 8.4em;
        grid-template-columns: 390px 1fr;
          a {
            letter-spacing: 3px;
          }
        div h2 {
          font-size: 2.25rem;
        }
      }
    }
    @media (min-width: 1200px) {
      #svg-phone,
      #svg-mail {
        fill: white;
      }
      .about,
      .question,
      .email p,
      .office-contact div h2 {
        color: white;
      }
      :before {
        content: "";
        background: var(--clr-accent);
        position: absolute;
        top: 0;
        bottom: 1160px;
        right: 0;
        width: calc(50% + 180px);
        z-index: 0;
      }
      .question {
        border: 4px solid white;
      }
    }
  }
`
